import currency from "currency.js";

export const USD = value => currency(value, {symbol:'$', precision: 0}).format()
export const USD_P2 = value => currency(value, {symbol:'$', precision: 2}).format()

const KILOBYTE = 1024;
const MEGABYTE = 1048576;
const convertFileSize = (bytes) => {
    if(bytes > MEGABYTE) {
       return  ['MB', bytes / MEGABYTE]
    }
    if(bytes > KILOBYTE) {
        return ['KB', bytes / KILOBYTE]
    }
    return ["B", bytes]
}

export const formatFileSize = bytes => {
    const [symbol, value] = convertFileSize(bytes)
    return currency(value, {symbol: symbol, pattern: '# !', precision: 1}).format()
}

export default USD