import * as React from 'react';
import SideQuoteForm from '../components/layout/SideQuoteForm';
import HomeMenuDL from '../components/layout/HomeMenuDL';
import Seo from '../components/layout/Seo';
import Icons from '../components/icons/Icons';
import { graphql } from 'gatsby';

import '../styles/main.scss';

const LandingPageTemp = ({ data }) => {
  const [readMore, setReadMore] = React.useState(false);
  const handleReadMore = React.useCallback(() => {
    setReadMore(_readMore => !_readMore);
  }, []);

  // get first text-block
  const firstTextBlock = data.page?.blocks.find(
    f => f.strapi_component === 'blocks.text-block'
  ) || {
    Heading: '',
    Description: '',
  };

  let page = data.page;
  let style = {};
  if (page.hero_image_url) {
    style['backgroundImage'] = `url(${page.hero_image_url})`;
  }

  return (
    <section className={'w-full'}>
      <Seo
        title={page.meta_title}
        canonical={'/' + page.slug + '/'}
        description={page.meta_description}
        article={page}
      />
      <Icons />
      <HomeMenuDL />
      <header
        className={
          (readMore ? 'h-[600px]' : 'h-80 2xl:h-72') +
          ' group min-h-80 flex flex-row bg-gradient-to-t from-gray-200 to-[rgba(0,0,0,0)_10%] duration-300 lg:px-[8.33%] '
        }
      >
        {page.hero_image_url && (
        <div
          className={
            (readMore ? 'hidden' : 'hidden md:flex') +
            ' relative w-1/2 justify-end overflow-hidden object-contain'
          }
        >
          <img
            src={page.hero_image_url}
            loading="eager"
            alt={page.title}
            className="absolute top-[50%] -z-10 h-full -translate-y-[50%] scale-[110%] grayscale duration-500 group-hover:grayscale-0"
          />
        </div> )}
        <div
          className={
            (readMore ? ' max-w-[800px] ' : 'w-full') +
            ' mx-auto flex flex-col items-start justify-center  p-5'
          }
        >
          <h1 className="py-2 text-2xl font-black uppercase md:text-4xl">
            {/* H1 Title goes here */}
            {page.title}
          </h1>
          <p className={readMore ? ' overflow-auto py-1' : 'py-1 line-clamp-5'}>
            {/* First text block goes here */}
            <p dangerouslySetInnerHTML={{ __html: firstTextBlock.Description || firstTextBlock.description }} />
          </p>
          {readMore ? (
            <a
              href="#read-more"
              onClick={handleReadMore}
              className="my-2 rounded-md border border-gray-400 bg-white px-3 py-1 uppercase duration-300 hover:font-bold hover:shadow-lg"
            >
              Continue Reading
            </a>
          ) : (
            <button
              onClick={handleReadMore}
              className="my-2 rounded-md border border-gray-400 bg-white px-3 py-1 uppercase duration-300 hover:font-bold hover:shadow-lg"
            >
              {readMore ? 'Read Less' : 'Read More'}
            </button>
          )}
        </div>
      </header>

      <section className="mx-auto flex w-10/12 max-w-[1366px] flex-col lg:flex-row">
        {/* second text block goes here */}
        <a id="read-more" />
        <div className="order-2 p-3 lg:w-2/3" dangerouslySetInnerHTML={{ __html: page.content }} />
        <aside className="relative order-1 py-2 lg:order-2 lg:w-1/3 lg:px-2">
          <SideQuoteForm tag={"landing-page"} />
        </aside>
      </section>

    </section>
  );
};

export const query = graphql`
  query CmsLandingPageQuery($pageId: String!) {
    page: strapiPage(id: { eq: $pageId }) {
      title
      content
      meta_title
      meta_description
      slug
      hero_image_url
      created_at
      updated_at
      blocks {
        strapi_component
        heading
        Heading
        id
        description
        Description
      }
    }
  }
`;

export default LandingPageTemp;
