const cascade = (...validators) =>
{
    return (value) => {
        let validation = {isValid: true, message: ""};
        for (const validator of validators) {
            validation = validator(value)
            if (!validation.isValid) {
                return validation;
            }
        }
        return validation;
    }
}
export default cascade;