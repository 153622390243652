import * as React from "react"
import { memo } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { USD_P2 } from "../../helpers/NumberHelper"
import { buildAbsoluteUrl } from "../../helpers/UrlHelpers"

const ProductStructuredMetadata = ({ site, product }) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Product",
    name: product.name,
    sku: product.sku,
    mpn: product.sku,
    description: product.description,
    image: product.image.url,
    url: `${site.siteUrl}/${product.url_key}/`,
    offers: [
      {
        "@type": "Offer",
        priceCurrency: "USD",
        price: product.price,
        availability:
          product.qty > 0 ? "https://schema.org/InStock" : "https://schema.org/LimitedAvailability",
        seller: {
          "@type": "Organization",
          name: site.name,
        },
        url: `${site.siteUrl}/${product.url_key}/`,
        priceValidUntil: product.stock_date,
      },
    ],
    brand: product.brand,
    // "aggregateRating": {
    //     "@type": "AggregateRating",
    //     "ratingValue": 5,
    //     "bestRating": 5,
    //     "reviewCount": 21
    // },
    // "review": {
    //     "@type": "Review",
    //     "reviewRating": {
    //         "@type": "Rating",
    //         "ratingValue": "5",
    //         "bestRating": "5"
    //     },
    //     "author": {
    //         "@type": "Person",
    //         "name": "Jackie March"
    //     }
    // }
  })
}

const WebPageStructuredMetadata = ({ site }) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: site.title,
    alternateName: site.alternateName,
    url: `${site.siteUrl}/`,
    potentialAction: {
      "@type": "SearchAction",
      target: `${site.siteUrl}/search/?q={search_term_string}`,
      "query-input": "required name=search_term_string",
      name: "Search",
    },
  })
}

const LocalBusinessStructuredMetadata = ({ site }) => {
  // (vide: https://www.gatsbyjs.com/docs/how-to/adding-common-features/seo/)
  return JSON.stringify({
    "@context": "https://schema.org/",
    "@type": site.businessType,
    address: {
      "@type": "PostalAddress",
      addressLocality: site.addressCity,
      addressRegion: site.addressState,
      postalCode: site.addressZip,
      streetAddress: site.addressStreet,
    },
    name: site.name,
    url: `${site.siteUrl}/`,
    telephone: site.phone,
    email: site.email,
    priceRange: site.priceRange,
    founder: site.author,
    image: site.image,
    foundingDate: site.foundingYear,
    legalName: site.legalName,
    logo: site.logo,
    areaServed: {
      "@type": "State",
      name: "Florida",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 25.8869068,
      longitude: -80.1236891,
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+1-305-770-6955",
      contactType: "Selling Department of Gray and Sons",
      availableLanguage: ["English", "Spanish", "Russian", "Portuguese", "Polish"],
    },
    paymentAccepted: "Cash, Credit Card (American Express, Visa, Mastercard), PayPal, Affirm",
    openingHours: "Mo,Tu,We,Th,Fr,Sa 10:00-17:00",
  })
}

const OrganizationStructuredMetadata = ({ site, reviews }) => {
  return JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Organization",
    address: {
      "@type": "PostalAddress",
      addressLocality: site.addressCity,
      addressRegion: site.addressState,
      postalCode: site.addressZip,
      streetAddress: site.addressStreet,
    },
    name: site.name,
    url: `${site.siteUrl}/`,
    telephone: site.phone,
    email: site.email,
    founder: site.author,
    image: site.image,
    foundingDate: site.foundingYear,
    legalName: site.legalName,
    logo: site.logo,
    areaServed: {
      "@type": "State",
      name: "Florida",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: (reviews.sum / reviews.total).toFixed(1),
      reviewCount: reviews.total,
    },
    sameAs: [
      site.facebook,
      site.twitter,
      site.linkedin,
      site.instagram,
      site.youtube,
      site.pinterest,
    ],
  })
}

const getDate = (article, product, data) => {
  if (article) return article.created_at
  if (product) return product.stock_date
  return data.updated_at
}
const getModDate = (article, data) => {
  if (article) return article.updated_at
  return data.updated_at
}

const GeneralStructuredMetadata = ({
  site,
  canonical,
  article,
  product,
  defaultTitle,
  data,
  title,
}) => {
  const schemaObjects = []
  if (article) {
    const articlePart = {
      "@type": "Article",
      headline: article.title,
      keywords: article.meta_description,
      datePublished: article.created_at,
      dateModified: article.created_at,
      author: { "@id": canonical + "#author" },
      publisher: { "@id": canonical + "#organization" },
      description: article.meta_description,
      name: article.meta_title,
      "@id": canonical + "#richSnippet",
      isPartOf: { "@id": canonical + "#webpage" },
      inLanguage: "en-US",
      mainEntityOfPage: { "@id": canonical + "#webpage" },
    }
    if (article.hero_image_url) {
      articlePart["image"] = { "@id": article.hero_image_url }
    }
    schemaObjects.push(articlePart)
  }
  schemaObjects.push({
    "@type": "Organization",
    "@id": canonical + "#organization",
    name: site.name,
    url: canonical,
    logo: {
      "@type": "ImageObject",
      "@id": canonical + "#logo",
      url: "https://sellusyourjewelry.com/wp-content/uploads/2019/12/photo_2019-12-26_17-32-13.jpg",
      name: site.title,
      caption: site.title,
      inLanguage: "en-US",
      width: "1280",
      height: "356",
    },
  })
  schemaObjects.push({
    "@type": "WebSite",
    "@id": canonical + "#website",
    url: canonical,
    name: site.title,
    publisher: {
      "@id": canonical + "#organization",
    },
    inLanguage: "en-US",
  })
  schemaObjects.push({
    "@type": "ImageObject",
    "@id": `${site.siteUrl}/logo-900.jpg`,
    url: `${site.siteUrl}/logo-900.jpg`,
    width: "900",
    height: "235",
    caption: site.alternateName,
    inLanguage: "en-US",
  })
  schemaObjects.push({
    "@type": "Person",
    "@id": canonical + "#author",
    name: site.author,
    url: canonical + "about-us/",
    image: {
      "@type": "ImageObject",
      "@id": `${site.siteUrl}/keith.jpg`,
      url: `${site.siteUrl}/keith.jpg`,
      caption: site.author,
      inLanguage: "en-US",
    },
    worksFor: {
      "@id": canonical + "#organization",
    },
  })
  let primaryImageOfPage = {}
  if (article && article.hero_image_url) {
    primaryImageOfPage = { primaryImageOfPage: { "@id": article.hero_image_url } }
  }
  schemaObjects.push({
    "@type": "WebPage",
    "@id": canonical + "#webpage",
    url: canonical,
    name: title || defaultTitle,
    datePublished: getDate(article, product, data),
    dateModified: getModDate(article, data),
    author: {
      "@id": canonical + "#author",
    },
    isPartOf: {
      "@id": canonical + "#website",
    },
    inLanguage: "en-US",
    ...primaryImageOfPage,
  })
  return JSON.stringify({
    "@context": "https://schema.org",
    "@graph": schemaObjects,
  })
}

const getData = () => {
  const { data, reviews } = useStaticQuery(
    graphql`
      query {
        reviews: allStrapiGoogleReview {
          sum: sum(field: rating)
          total: totalCount
        }
        data: site {
          trailingSlash
          siteMetadata {
            title
            description
            author
            siteUrl
            alternateName
            name
            addressCity
            addressState
            addressZip
            addressStreet
            phone
            email
            foundingYear
            legalName
            priceRange
            businessType
            image
            logo
            facebook
            twitter
            linkedin
            instagram
            youtube
            pinterest
            twitterUsername
          }
          updated_at: buildTime
        }
      }
    `
  )
  const site = data.siteMetadata
  return { site, data, reviews }
}

const getContentHero = (article, product, site) => {
  if (article && article.hero_image_url) {
    return article.hero_image_url
  }
  if (product && product.image && product.image.url) {
    return product.image.url
  }
  return site.logo
}

const getTwitterAndFBMetadata = ({
  site,
  lang,
  canonical,
  article,
  product,
  title,
  metaDescription,
  content_hero_image,
  defaultTitle,
}) => {
  const twitterMeta = []
  if (product && product.price && product.qty > 0) {
    twitterMeta.push({ name: `twitter:data1`, content: USD_P2(product.price) })
    twitterMeta.push({ name: `twitter:label1`, content: "Price" })
    twitterMeta.push({
      name: `twitter:data2`,
      content: product.brand && product.model ? `${product.brand} ${product.model}` : product.sku,
    })
    twitterMeta.push({
      name: `twitter:label2`,
      content: product.brand && product.model ? "Brand" : "SKU",
    })
  }
  let ogType = "website"
  if (article) ogType = "article"
  if (product) ogType = "product"
  return [
    { name: `description`, content: metaDescription },
    { property: `og:site_name`, content: site.alternateName },
    { property: `og:locale`, content: lang },
    { property: `og:url`, content: canonical },
    { property: `og:title`, content: title || defaultTitle },
    { property: `og:type`, content: ogType },
    { property: `og:description`, content: metaDescription },
    { property: `og:image`, content: content_hero_image },
    { property: `og:image:alt`, content: title || defaultTitle || site.name },
    { name: `twitter:card`, content: product && product.qty > 0 ? `product` : `summary` },
    { name: `twitter:creator`, content: site.twitterUsername },
    { name: `twitter:title`, content: title || defaultTitle },
    { name: `twitter:description`, content: metaDescription },
    { name: `twitter:image`, content: content_hero_image },
    { name: `twitter:image:alt`, content: title || defaultTitle || site.name },
  ].concat(twitterMeta)
}

const Seo = memo(({ description, lang, meta, title, canonical, noindex, product, article }) => {
  const { site, data, reviews } = getData()
  const metaDescription = description || site.description
  const defaultTitle = site?.title
  const content_hero_image = getContentHero(article, product, site)
  const absoluteCanonicalUrl = buildAbsoluteUrl(canonical, {
    siteMetadata: site,
    trailingSlash: "always",
  })
  /*
    - `twitter:card`: content type

     */
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title || defaultTitle}
      defer={false}
      meta={getTwitterAndFBMetadata({
        site,
        lang,
        canonical: absoluteCanonicalUrl,
        article,
        product,
        title,
        metaDescription,
        content_hero_image,
        defaultTitle,
      }).concat(meta)}
    >
      {noindex ? (
        <meta name="robots" content="noindex, nofollow, nosnippet, noarchive, nocache" />
      ) : (
        ""
      )}
      {/*<link rel={'preload'} as={'script'} href={'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js'} crossOrigin={'anonymous'}/>*/}
      {/*<link rel={'preconnect'} href={'https://cdn.sellusyourjewelry.com'} crossOrigin={'anonymous'}/>*/}
      {/*<link rel={'preconnect'} href={'https://cdn.rudderlabs.com'} crossOrigin={'anonymous'}/>*/}
      {/*<link rel={'preconnect'} href={'https://api.rudderlabs.com'} crossOrigin={'anonymous'}/>*/}
      {/*<link rel={'preconnect'} href={'https://dibbygrzeolg.dataplane.rudderstack.com'} crossOrigin={'anonymous'}/>*/}
      {canonical ? <link rel="canonical" href={absoluteCanonicalUrl} /> : ""}
      {product ? (
        <script type="application/ld+json">{ProductStructuredMetadata({ site, product })}</script>
      ) : (
        ""
      )}
      <script type="application/ld+json">{WebPageStructuredMetadata({ site })}</script>
      <script type="application/ld+json">{LocalBusinessStructuredMetadata({ site })}</script>
      <script type="application/ld+json">
        {OrganizationStructuredMetadata({ site, reviews })}
      </script>
      <script type="application/ld+json">
        {GeneralStructuredMetadata({
          site,
          canonical: absoluteCanonicalUrl,
          product,
          article,
          defaultTitle,
          data,
          title,
        })}
      </script>
    </Helmet>
  )
})

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: "",
  canonical: "",
  noindex: false,
  product: false,
  article: false,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
  noindex: PropTypes.bool,
  product: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  article: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

export default Seo
