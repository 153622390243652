import * as React from "react"
import { useEffect, useCallback } from "react"
import FormWrapper from "../form/FormWrapper"
import useFormState from "../form/hooks/useFormState"
import InputField from "../form/InputField"
import FileUpload from "../form/FileUpload"
import isBrandWeBuy from "../form/validators/isBrandWeBuy"
import isNotEmpty from "../form/validators/isNotEmpty"
import cascade from "../form/validators/cascade"
import PropTypes from "prop-types"

const CheckboxOptions = ({ option, form, name, onChange }) => {
  const onChangeHandler = e => {
    if (onChange) {
      onChange(e, name)
    }
  }
  return (
    <label className="flex flex-row items-center gap-2">
      <input
        type="checkbox"
        className="peer m-2 h-4 w-4 translate-y-[.25rem] border border-gray-400 accent-red-700 sm:h-5 sm:w-5"
        value={option}
        onChange={onChangeHandler}
        checked={form[name]}
      />
      <p className=" inline-block text-gray-400 hover:text-black peer-checked:text-black ">
        {option}
        {form[name]}
      </p>
    </label>
  )
}
CheckboxOptions.propTypes = {
  option: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

const SideQuoteForm = ({ tag = "", expandForm = false }) => {
  const [form, setForm, updateForm] = useFormState({
    fName: "",
    lName: "",
    phone: "",
    email: "",
    type: "watches",
    brand: "",
    model: "",
    caseSize: "",
    box: false,
    papers: false,
    booklets: false,
    extras: false,
    carat: "",
    clarity: "",
    color: "",
    pieces: "",
    weight: "",
    description: "",
    photos: [],
    tag,
  })
  const handleChange = useCallback(
    (e, name) => {
      setForm(f => {
        let newForm = { ...f }
        newForm[name] = e.target.checked
        return newForm
      })
    },
    [setForm]
  )
  const validateWatchBrandExt = useCallback(
    brand => {
      if (form["type"] === "watches") {
        return cascade(isNotEmpty, isBrandWeBuy)(brand, form)
      }
      return { isValid: true, message: "" }
    },
    [form["type"]]
  )
  useEffect(() => {
    setForm(_f => {
      return { ..._f, tag }
    })
  }, [tag])
  const updateUploadedFiles = useCallback(
    files =>
      setForm(f => {
        return { ...f, photos: files }
      }),
    [setForm]
  )
  useEffect(() => {
    window.collectchat = window.collectchat || {}
    if (window.collectchat.unload) {
      window.collectchat.unload()
    }
    window.collectchat.ready = () => {
      for (let i = 1; i < 10; ++i) {
        setTimeout(() => {
          window.collectchat.unload()
        }, 500 * i)
      }
    }
  }, [])
  const rows = () => {
    switch (form.type) {
      case "watches":
        return "4"
      case "diamonds":
        return "2"
      case "jewelry":
        return "6"
      default:
        return "4"
    }
  }
  return (
    <div className="lg:sticky top-16 ">
      <div className="flex w-full flex-col justify-center align-middle">
        <FormWrapper
          className={
            "bg-white py-4 shadow-lg border-[1px] rounded-md form-checkbox overflow-y-auto lg:max-h-[75vh] flex flex-col "
          }
          name={"Landing Pages - Side Form"}
          action={"/thank-you/"}
          form={form}
          minimumTimeout={1000}
        >
          <h2 className="text-center text-2xl pl-3 sm:pl-0 md:text-3xl pb-4 uppercase text-accentMd shadow-lg border-b border-200 z-30 ">
            GET YOUR FREE QUOTE
          </h2>
          <div className="flex flex-col space-y-4 h-full overflow-y-auto p-4 relative">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4">
              <div
                className={
                  "col-span-1 sm:col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 md:gap-x-3 "
                }
              >
                <InputField
                  name={"fName"}
                  label={"First Name"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"lName"}
                  label={"Last Name"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"phone"}
                  label={"Phone"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"email"}
                  label={"E-Mail"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
              </div>
              <div className={"border-[1px] p-3 rounded-lg col-span-1 sm:col-span-2"}>
                <p className="text-accent">What would you like to sell?</p>
                <div className=" flex flex-row relative group">
                  <div className="text-sm text-gray-400 absolute z-40 -rotate-45 right-4 group-hover:text-accent top-0 translate-y-2">
                    &#9699;
                  </div>
                  <select name="type" id="type" onChange={updateForm} className="relative">
                    <option value="watches">WATCH</option>
                    <option value="jewelry">JEWELRY</option>
                    <option value="diamonds">DIAMOND</option>
                    <option value="silver">STERLING SILVER</option>
                  </select>
                </div>

                {form.type === "watches" && (
                  <>
                    <div className="flex flex-row relative group space-y-4 ">
                      <div className="text-sm mt-4 text-gray-400 absolute z-40 -rotate-45 right-4 group-hover:text-accent top-0 translate-y-2">
                        &#9699;
                      </div>
                      <select
                        name="brand"
                        required
                        id="brand"
                        onChange={updateForm}
                        className="relative"
                      >
                        <option value="">Select Brand</option>
                        <option value="A. Lange & Söhne">A. Lange & Söhne</option>
                        <option value="Audemars Piguet">Audemars Piguet</option>
                        <option value="Bedat">Bedat</option>
                        <option value="Bell & Ross">Bell & Ross</option>
                        <option value="Bedat">Bedat</option>
                        <option value="Blancpain">Blancpain</option>
                        <option value="Bedat">Bedat</option>
                        <option value="Breguet">Breguet</option>
                        <option value="Breitling">Breitling</option>
                        <option value="Cartier">Cartier</option>
                        <option value="Chanel">Chanel</option>
                        <option value="Chopard">Chopard</option>
                        <option value="Chronoswiss">Chronoswiss</option>
                        <option value="Concord">Concord</option>
                        <option value="Corum">Corum</option>
                        <option value="David Yurman">David Yurman</option>
                        <option value="Ebel">Ebel</option>
                        <option value="Ernst Benz">Ernst Benz</option>
                        <option value="F. P. Journe">F. P. Journe</option>
                        <option value="Franck Muller">Franck Muller</option>
                        <option value="Gerald Genta">Gerald Genta</option>
                        <option value="Girard-Perregaux">Girard-Perregaux</option>
                        <option value="Glashutte">Glashutte</option>
                        <option value="Harry Winston">Harry Winston</option>
                        <option value="Hublot">Hublot</option>
                        <option value="IWC">IWC</option>
                        <option value="Jacob & Co.">Jacob & Co.</option>
                        <option value="Jaeger-LeCoultre">Jaeger-LeCoultre</option>
                        <option value="Louis Vuitton">Louis Vuitton</option>
                        <option value="Maurice Lacroix">Maurice Lacroix</option>
                        <option value="Montblanc">Montblanc</option>
                        <option value="Omega">Omega</option>
                        <option value="Panerai">Panerai</option>
                        <option value="Parmigiani Fleurier">Parmigiani Fleurier</option>
                        <option value="Patek Philippe">Patek Philippe</option>
                        <option value="Piaget">Piaget</option>
                        <option value="Richard Mille">Richard Mille</option>
                        <option value="Roger Dubuis">Roger Dubuis</option>
                        <option value="Rolex">Rolex</option>
                        <option value="Tag Heuer">Tag Heuer</option>
                        <option value="Tiffany & Co.">Tiffany & Co.</option>
                        <option value="Tudor">Tudor</option>
                        <option value="Ulysse Nardin">Ulysse Nardin</option>
                        <option value="Vacheron Constantin">Vacheron Constantin</option>
                        <option value="Van Cleef & Arpels">Van Cleef & Arpels</option>
                        <option value="Zenith">Zenith</option>
                      </select>
                    </div>

                    <InputField
                      name={"model"}
                      label={"Watch Model"}
                      form={form}
                      onChange={updateForm}
                      validator={validateWatchBrandExt}
                      required={false}
                    />
                    <div
                      className={
                        expandForm ? " md:grid md:grid-cols-2 md:gap-x-4" : "flex flex-col "
                      }
                    >
                      <InputField
                        name={"caseSize"}
                        label={"Case Size (mm)"}
                        form={form}
                        onChange={updateForm}
                        validator={validateWatchBrandExt}
                        required={false}
                        className={expandForm ? "md:col-span-1 col-span-2 " : "col-span-2"}
                      />
                      <div
                        className={
                          (expandForm ? " col-span-2 md:col-span-1 " : "col-span-2") +
                          " flex flex-row justify-between mt-4 text-sm md:text-base z-50"
                        }
                      >
                        <CheckboxOptions
                          form={form}
                          onChange={handleChange}
                          name={"box"}
                          option={"Box"}
                        />
                        <CheckboxOptions
                          form={form}
                          onChange={handleChange}
                          name={"papers"}
                          option={"Papers"}
                        />
                        <CheckboxOptions
                          form={form}
                          onChange={handleChange}
                          name={"booklets"}
                          option={"Booklets"}
                        />
                        <CheckboxOptions
                          form={form}
                          onChange={handleChange}
                          name={"extras"}
                          option={"Extras"}
                        />
                      </div>
                    </div>
                  </>
                )}

                {form.type === "diamonds" && (
                  <>
                    <div className="grid grid-cols-2 gap-3">
                      <InputField
                        name={"carat"}
                        label={"Carat Weight"}
                        form={form}
                        onChange={updateForm}
                      />
                      <InputField
                        name={"clarity"}
                        label={"Clarity"}
                        form={form}
                        onChange={updateForm}
                      />
                    </div>
                    <InputField name={"color"} label={"Color"} form={form} onChange={updateForm} />
                  </>
                )}

                {form.type === "silver" && (
                  <div className="grid grid-cols-2 gap-3">
                    <InputField
                      name={"pieces"}
                      label={"Total Pieces"}
                      form={form}
                      onChange={updateForm}
                      required={true}
                    />
                    <InputField
                      name={"weight"}
                      label={"Weight in grams"}
                      form={form}
                      onChange={updateForm}
                      required={true}
                    />
                  </div>
                )}

                <InputField
                  type={"textarea"}
                  rows={rows()}
                  name={"description"}
                  label={"Description"}
                  form={form}
                  onChange={updateForm}
                />
              </div>
            </div>
            <div className="w-full mt-2">
              <FileUpload
                id={"photos"}
                label={"Add photos"}
                name={"photos"}
                accept={".jpg,.png,.jpeg"}
                required={false}
                updateFilesCb={updateUploadedFiles}
                multiple
              />
              * Max. file size: 40 MB, Max. files: 5.
            </div>
            <button className="bg-accent hover:bg-accentMd text-white w-[15rem] rounded-lg px-5 py-3 my-3">
              SUBMIT FORM
            </button>
          </div>
        </FormWrapper>
      </div>
    </div>
  )
}

export default SideQuoteForm
