import * as React from "react"
import { Link } from "gatsby"

const excludeKeywords = [
  "align",
  "alphina",
  "anna klein",
  "anthony",
  "apple",
  "apple series 1",
  "apple series 2",
  "apple series 3",
  "apple series 4",
  "apple series 5",
  "apple watch",
  "araba",
  "armand",
  "armand nicolet",
  "armani",
  "audi",
  "balair",
  "balmer",
  "barnocelli",
  "benrus",
  "bijoux",
  "bolvaint",
  "bulova",
  "burberry",
  "buren",
  "calvin klein",
  "casio",
  "certalda",
  "china",
  "citizen",
  "coach",
  "columbus",
  "coma",
  "counterfelt",
  "croton",
  "daniel wellington",
  "diesel",
  "dolce",
  "dolce gabbana",
  "dora",
  "ebay",
  "edox",
  "elgin",
  "ernest borel",
  "eska",
  "esq",
  "faceto",
  "fake",
  "farer",
  "fendi",
  "fossil",
  "frederique constant",
  "frédérique constant",
  "g-shok",
  "galaxy",
  "gauche",
  "general",
  "gerbil",
  "gevril",
  "gf ferre",
  "gruen",
  "gshok",
  "gucci",
  "gucci ",
  "gucci gold",
  "gucci gold plated",
  "gucci watch",
  "guess",
  "hamilton",
  "helbros",
  "heritor",
  "honda",
  "hugo boss",
  "invicta",
  "isq",
  "iwatch",
  "jerome lemurs",
  "jinghan",
  "joe",
  "joe rodeo",
  "kenneth cole",
  "kors",
  "kristine quartz",
  "longines",
  "lorus",
  "macarthur",
  "macarthurs",
  "marc jacobs",
  "mcarthurs",
  "michael",
  "michael kors",
  "modaine",
  "movado",
  "moved",
  "mvmt",
  "nautica",
  "navajo",
  "nixon",
  "nobel",
  "nomos",
  "nordgreen",
  "oceaneva",
  "oris",
  "pandora",
  "pearls",
  "philip stein",
  "phone",
  "plated",
  "polo assn",
  "pulsar",
  "rado",
  "replica",
  "ritmo mundo",
  "rockford",
  "rodeo",
  "samsung",
  "see-gull",
  "seiko",
  "shingle",
  "shinola",
  "sinn",
  "skagen",
  "smart",
  "smartwact",
  "smith &amp; wesson",
  "smith and wesson",
  "soviet",
  "stauer",
  "steinhart",
  "stuhrling",
  "swatch",
  "swiss army",
  "swissarmy",
  "timex",
  "tissel",
  "tissot",
  "tokyobay",
  "tommy hilfiger",
  "triwa",
  "tsovet",
  "uniform wares",
  "versace",
  "victorinox",
  "waltham",
  "wittnauer",
  "xeros",
]

const isBrandWeBuy = brand => {
  const isValid =
    excludeKeywords.filter(keyword => brand.toLowerCase().includes(keyword)).length === 0
  if (!isValid) {
    return {
      isValid,
      message: (
        <>
          Sorry, we are not buying <b> {brand}</b> watches. Check all{" "}
          <Link to={"/brands-we-buy"} className={"font-bold text-black hover:text-red-500"}>
            brands we buy
          </Link>{" "}
          to learn more.
        </>
      ),
    }
  }
  return { isValid, message: "" }
}

export default isBrandWeBuy
