const buildAbsoluteUrl = (url, site) => {
    const siteUrl = site.siteMetadata.siteUrl;
    const trailingSlash = site.trailingSlash === "always";
    // if url starts with http[s]://, the url is already absolute
    if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
    }
    // if trailing slash is required, add it
    if (trailingSlash && !url.endsWith("/")) {
        url += "/";
    }
    // remove trailing slash for siteUrl (if it has one)
    let siteUrlWithoutTrailingSlash = siteUrl;
    if (siteUrl.endsWith("/")) {
        siteUrlWithoutTrailingSlash = siteUrl.substring(0, siteUrl.length - 1);
    }
    // make sure url starts with /
    if (!url.startsWith("/")) {
        url = "/" + url;
    }
    return siteUrlWithoutTrailingSlash + url;
}

exports.buildAbsoluteUrl = buildAbsoluteUrl