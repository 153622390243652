import * as React from "react"
import { useState, useCallback } from "react"
import isNotEmpty from "./validators/isNotEmpty"
import inputValidator from "./validators/inputValidator"
import formatErrorMessage from "./helpers/formatErrorMessage"

const InputField = ({
  name,
  label,
  required = false,
  onChange,
  form,
  className = "",
  type = "text",
  rows = null,
  validator = isNotEmpty,
}) => {
  const [focus, setFocus] = useState(false)
  const [pristine, setPristine] = useState(true)
  const [validation, setValidation] = useState({ isValid: true, message: "" })

  const error = !validation.isValid && !pristine

  const onChangeHandler = useCallback(
    e => {
      setPristine(false)
      const validation = inputValidator(e.target.value, validator, required, true, false)
      setValidation(validation)
      e.target.valid = validation.isValid
      if (onChange) onChange(e)
    },
    [onChange, setPristine, setValidation, required, validator]
  )

  const onFocus = useCallback(() => {
    setFocus(true)
  }, [setFocus])
  const onBlur = useCallback(
    e => {
      setFocus(false)
      setPristine(false)
      setValidation(inputValidator(e.target.value, validator, required, false, false))
    },
    [setFocus, setPristine, required, validator, setValidation]
  )
  const errorStyle = error ? " border-red-500 " : ""
  return (
    <div className={"w-full bg-white " + className}>
      <div
        className={
          "pointer-events-none h-4 transform px-2 text-gray-500 duration-100 ease-out " +
          (focus || (form && form[name]) ? "translate-y-1 text-xs " : "translate-y-6")
        }
      >
        <label className={"bg-white px-2" + (error ? " text-red-500" : "")} htmlFor={name}>
          {" "}
          <nobr>
            {label}: {required ? "*" : ""}
          </nobr>
        </label>
      </div>
      {rows ? (
        <textarea
          className={"w-full border px-2 autofill:bg-white" + errorStyle}
          id={name}
          name={name}
          rows={rows}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChangeHandler}
        >
          {form[name]}
        </textarea>
      ) : (
        <input
          className={"h-10 w-full border px-2 autofill:bg-white" + errorStyle}
          type={type}
          id={name}
          name={name}
          value={form[name]}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChangeHandler}
        />
      )}
      {error ? (
        <>
          <div className="relative -top-[.6rem] left-5 w-3 h-3 z-50 border-t-2 border-l-2 bg-white border-red-500 rotate-45"></div>
          <div className="bg-white mr-14 border-2 border-red-500 z-40 py-1 px-2 shadow-md rounded-lg absolute text-sm text-red-700 -translate-y-4 -translate-x-2">
            {formatErrorMessage(validation, label)}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default InputField
